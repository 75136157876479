import { Box } from '@missionlabs/smartagent-app-components'
import { useEffect, useRef } from 'react'
import { H } from 'react-accessible-headings'
import ContactState from 'store/contact/contact.state'

import classNames from 'classnames'
import videojs from 'video.js'
import type Player from 'video.js/dist/types/player'
import 'video.js/dist/video-js.css'
import { IInteraction } from 'views/Metrics/HistoricMetrics/types'

import { Loader } from 'components'
import useHasFeature, { AppFeatures, AppSubFeatures } from 'hooks/useHasFeature'
import getVideoMeetingValue from 'utils/getVideoMeetingValue'
import { ScreenshotButton } from '../ScreenshotButton'
import { Screenshots } from '../Screenshots'
import { ScreenshotsHeading } from '../Screenshots/ScreenshotsHeading'
import { useHistoricSessionMedia } from '../hooks/useHistoricSessionMedia'
import { Message } from './Message'
import styles from './index.module.scss'

export interface VideoSharingHistoryProps {
    contact?: IInteraction | ContactState
}

export const VideoSharingHistory = ({ contact }: VideoSharingHistoryProps) => {
    const videoRef = useRef<HTMLVideoElement>(null)
    const videoPlayer = useRef<Player | null>(null)

    const meetingID =
        getVideoMeetingValue(contact?.ID ?? '', contact?.attributes['sa-video-meeting-id']) ?? ''

    const canUseFeature = useHasFeature()
    const canEditVideoSession = canUseFeature(AppFeatures.VIDEO_MEETING, [
        AppSubFeatures.VIDEO_MEETING_EDIT_RECORDS,
    ])

    const { playbackURL, screenshots } = useHistoricSessionMedia({
        meetingID,
        contactID: contact?.ID || '',
    })

    useEffect(() => {
        if (!playbackURL) return

        const video = videoRef.current

        if (!video) return

        const player = videojs(video, {
            controls: true,
            autoplay: false,
            preload: 'auto',
            fluid: true,
            disablePictureInPicture: true,
            playbackRates: [0.5, 1, 1.5, 2],
            inactivityTimeout: 0,
            children: {
                ControlBar: {
                    fullScreenToggle: false,
                    pictureInPicture: false,
                },
            },
        })

        player.src({
            src: playbackURL,
            type: 'application/vnd.apple.mpegurl',
        })

        player.addClass(styles['vjs-sa-video-player'])

        videoPlayer.current = player
    }, [playbackURL])

    return (
        <>
            <Box alt collapse boxLabel="Video Sharing" header={<H>Video Sharing</H>}>
                <div className={styles.VideoContainer}>
                    {!!meetingID ? (
                        <>
                            <div
                                style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100%',
                                    height: '100%',
                                    display: playbackURL ? 'none' : 'flex',
                                }}
                            >
                                <Loader />
                            </div>
                            <div>
                                <video
                                    ref={videoRef}
                                    id="video-id"
                                    controls
                                    title="Video Meeting Recording"
                                    style={{ display: playbackURL ? 'block' : 'none' }}
                                    className={classNames(
                                        'video-js vjs-default-skin',
                                        styles.Video,
                                    )}
                                >
                                    <track kind="captions"></track>
                                </video>
                            </div>
                        </>
                    ) : (
                        <div>
                            <Message>There is no video sharing session for this contact</Message>
                        </div>
                    )}
                </div>
                {canEditVideoSession && !!meetingID && (
                    <div
                        className={classNames(
                            styles.ActionButtonsContainer,
                            videoPlayer?.current?.isFullscreen() ? styles.Hidden : '',
                        )}
                    >
                        <ScreenshotButton
                            videoRef={videoRef}
                            isHistorical
                            attributeAttachmentCount={
                                contact?.attributes['sa-video-meeting-attachment-count']
                            }
                        />
                    </div>
                )}
            </Box>
            <Box
                alt
                collapse
                boxLabel="Screenshots"
                header={<ScreenshotsHeading count={Object.keys(screenshots).length} />}
            >
                <div className={styles.ScreenshotsContainer}>
                    <Screenshots
                        screenshots={screenshots}
                        isHistorical
                        contactID={contact?.ID ?? ''}
                        attributeAttachmentCount={
                            contact?.attributes['sa-video-meeting-attachment-count']
                        }
                    />
                </div>
            </Box>
        </>
    )
}
